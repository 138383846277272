import * as React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

// styled label
const Label = styled.label`
  display: none;
  min-width: 110px;
  text-align: right;
  vertical-align: top;
  padding-right: 10px;
  margin-bottom: 20px;
  @media (max-width: 1024px) {
    display: none;
    min-width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
`;

//styled input
const Input = styled.input`
  width: calc(100% - 140px);
  padding: 5px;
  margin-bottom: 1rem;
  @media (max-width: 1024px) {
    display: block;
    width: 100%;
    text-align: left;
    border: 0.5px solid hsla(0, 0%, 0%, 0.33);
  }
`;

const ContactForm = () => (
<div>
    <h3>Get a Quote</h3>
    <p>Please fill out the form below and one of our staff will be in touch shortly.  </p>
    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" css={css`text-align:center;`}>
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />
        <p>
            <Label for="name">Name</Label>
            <Input type="text" name="name" id="name" placeholder="Name" />

            <Label for="email">Email</Label>
            <Input type="email" name="email" id="name" placeholder="email" />
        </p>
        <p>
            <Label for="tel">Phone Number</Label>
            <Input type="tel" name="telephone" id="tel" placeholder="(206) 555-5555"/>
        </p>
            <Label for="message">Message</Label>
            <textarea 
                name="message"
                placeholder="How can we help?"
                rows="4"
                css={css`
                    width: calc(100% - 140px);
                    min-width: 300px;
                    padding: 5px;
                    margin-bottom: 1rem;
                    @media (max-width: 1024px) {
                        display: block;
                        width: 100%;
                        margin-left: 0;
                        text-align: left;
                        border: 0.5px solid hsla(0, 0%, 0%, 0.33);
                    }
                `}
            />
        <p>
        <button
            type="submit"
            css={css`
              display: block;
              height: 3rem;
              width: 8rem;
              margin: auto;
              border-radius: 5px;
              color: white;
              background-color: #0192ff;
              border-color: blue;
              transition: all 0.3s;
              :hover {
                background-color: darkorange;
                transform: translateY(1px);
              }
              
            `}
          >
            Submit
          </button>
        </p>
  </form>
</div>
)


export default ContactForm
