import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import Services from "../components/services"
import Hero from "../components/hero"
import About from "../components/about"
import Testimonials from "../components/testimonials"
import Contact from "../components/contact"
import Survey from "../components/survey"

const IndexPage = () => (
  <Layout>
    <Seo title="Emergency Response | Mitigation | Repairs" />
    <Hero />
    <Services />
    {/* <About /> */}
    <Contact />
    {/* <Testimonials /> */}
  </Layout>
)

export default IndexPage
