import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import SectionTitle from "./sectionTitle"
import { StaticImage } from "gatsby-plugin-image"

const Section = styled('section')`
    min-height: 20rem;
    background-color:white;
    padding: 4rem;
    
    div {
        max-width: 1200px;
        margin:0 auto;
        
    }

    @media screen and (max-width: 640px) {
            width: 90%;
            margin: 1rem auto;
            padding: 0;
    }

    .service-main {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;

        @media screen and (max-width: 1024px) {
            display: block;
        }

        div {
            width: 100%;
            max-width: 600px;
            margin-bottom: 2rem;
        }
    }

    @media screen and (max-width: 1024px) {
        .service-main {
            display: block;
        }
    }

    .service-secondary>div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;

        @media screen and (max-width: 1024px) {
            display: block;
        }

        /* div {
            width: 100%;
            max-width: 600px;
            margin-bottom: 2rem;
        } */
    }
`


const Services = () => (
  <Section className="services" css={css`
 
  `}>
      <SectionTitle subTitle="About Us" mainTitle="Restoration and Mitigation Specialists" />
      <div className="service-main">
          <div>
            <p>Priority One Contractor Services, LLC. is second to none! We have led the restoration industry for over 10 years with empathy for our customers and recognize the impact that home damage or loss will have on you and your family. Our company mission is to make service to our customers our number one priority.</p>
                  
            <p>What does this mean for you? We will strive to get your home restored as quickly as possible. During this process, you can expect clean, quality workmanship, honest and thorough communication, and assistance navigating insurance claims. At Priority One, our customers matter...after all, this is your home. We will work in conjunction with you during the entire restoration process.</p>
                    
          </div>
          <div css={css`
            width: 100%;
            max-width: 600px;
            margin-bottom: 2rem;
          `}>
            <StaticImage
                layout="fullWidth"
                placeholder="blue"
                alt="construction Services"
                src="../images/man building wall.jpg"
                formats={["auto", "webp", "avif"]}
            />

          </div>
      </div>
      <div className="service-secondary">
        <h3 css={css` text-align: center;`}>Services</h3>
        <div>
          <div>
            <h4>Emergency Response</h4>
            <p>With quick response times, we act quickly to mitigate home damage. Some of the services our Emergency Response team can provide are:</p>
            <ul>
                <li>Covering a damaged roof</li>
                <li>Boarding up walls/windows</li>
                <li>Structural stabilization</li>
                <li>Temporary power</li>
                <li>Water/waste extraction</li>
            </ul>
          </div>
          <div>
            <h4>Repair/Reconstruction</h4>
            <p>Our repair and reconstruction team can take care of everything from start to finish. Services include, but are not limited to:</p>
            <ul>
                <li>Framing</li>
                <li>Drywall</li>
                <li>Flooring</li>
                <li>Painting</li>
                <li>Debris removal</li>
            </ul>
          </div>
          <div>
            <h4>Mitigation Services</h4>
            <p>With quick response times, we act quickly to mitigate home damage. Some of the services our Emergency Response team can provide are:</p>
            <ul>
                <li>Damage analysis</li>
                <li>Dry out</li>
            </ul>
          </div>
          <div>
            <h4>Contents</h4>
            <p>Our contents team is here to help you with your belongings (contents):</p>
            <ul>
                <li>Boxing/Packing</li>
                <li>Safely storing</li>
            </ul>
          </div>
        </div>
      </div>
  </Section>
)


export default Services
