import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import SectionTitle from "./sectionTitle"
import { StaticImage } from "gatsby-plugin-image"
import ContactForm from "./contactform"

const Section = styled('section')`
    min-height: 20rem;
    background-color: lightyellow;
    padding: 4rem;
    
    div {
        max-width: 1200px;
        margin:0 auto;
        
    }

    @media screen and (max-width: 640px) {
            
            padding: 0;
    }

    .contact-main {
        display: grid;
        place-items: center center;

        @media screen and (max-width: 640px) {
            width: 90%;
            padding: 0;
        }
    }

`

const Contact = () => (
  <Section className="contact" css={css`
 
  `}>
      <SectionTitle subTitle="Get Help" mainTitle="Contact Us" />
      <div className="contact-main" id="contact">
        {/* <ContactForm /> */}
            {/* <!-- Form --> */}

                <iframe 
                    src="https://buildertrend.net/leads/contactforms/ContactFormFrame.aspx?builderID=76004" 
                    scrolling="no" 
                    id="btIframe" 
                    css={css`
                        background:transparent;
                        border:0px;
                        margin:0 auto;
                        width:100%;
                    `}
                ></iframe> 

            {/* <!-- /Form --> */}
      </div>
  </Section>
)


export default Contact
