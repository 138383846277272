import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => (
  <section className="hero" css={css`
  display: grid;
  background-color: lightblue;
  height: 80vh; //TODO update after fixing header height
  `}>
      <StaticImage
        css={css`
          grid-area: 1/1;
        `}
        layout="fullWidth"
        // This is a presentational image, so the alt should be an empty string
        alt=""
        // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
        src="../images/interior.jpeg"
        formats={["auto", "webp", "avif"]}
      />
      {/* opacity layer */}
      <div css={css`
        display: grid;
          grid-area: 1/1;
          position: relative;
          background-color: #112413;
          opacity: 0.4;
      `}></div>
      {/* content layer */}
      <div
      css={css`
        display: grid;
          grid-area: 1/1;
          position: relative;
          align-items: center;
          max-width: 1200px;
          width: 100%;
          margin: 0 auto;
      `}
      >
        <div css={css`
            max-width: 70ch; 
            padding:2rem;
            @media screen and (max-width: 640px) {
                width: 90%;
                margin: 1rem auto;
                padding: 0;
        }
            `}>
            <h2 css={css`
                font-size: clamp(2rem, -0.875rem + 8.5vw, 4rem);
                color: white;
            `}>Wind, Fire, Water Damage? We can help!</h2>
            <p css={css`color:white;`}>Let the disaster restoration & mitigation experts from Priority One Contractor Services take care of it for you. We can handle any size of commercial or residential job.</p>
            <a href="#contact" css={css`
                display: block;
                width: 8rem;
                padding: 1rem;
                border-radius: 0.15rem;
                color: white;
                background-color: #0192ff;
                border-color: blue;
                text-decoration:none;
                transition: all 0.3s;
                    :hover {
                    background-color: darkblue;
                    transform: translateY(1px);
                    }
                `}>Get a Quote!</a>

        </div>
    </div>
  </section>
)


export default Hero
